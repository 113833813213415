import "bootstrap/dist/css/bootstrap.min.css";
//import { AnimatePresence, motion } from "framer-motion";
//import * as createHistory from "history";
import { lazy, Suspense } from "react";
import {
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RAFEntityName } from "../RAFComponents/helpers/Constants";
import LoginPage from "./../pages/LoginPage";
import LogoutPage from "./../pages/LogoutPage";

const AuthSuccessPage = lazy(() => import("../pages/AuthSuccessPage"));

const TaskDashboard = lazy(
  () => import("../RAFModules/Common/Task/TaskDashboard")
);
const ApprenticePage = lazy(
  () => import("../SkytrustModules/Administration/Apprentice/ApprenticePage")
);
const AreaIndexPage = lazy(
  () => import("../SkytrustModules/Administration/Area/AreaIndexPage")
);
const CodesAgencyPage = lazy(
  () => import("../SkytrustModules/Administration/CodesAgency/CodesAgencyPage")
);
const CodesMechanismPage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/CodesMechanism/CodesMechanismPage"
    )
);
const CodesNaturePage = lazy(
  () => import("../SkytrustModules/Administration/CodesNature/CodesNaturePage")
);
const CompanyPage = lazy(
  () => import("../SkytrustModules/Administration/Company/CompanyPage")
);
const CountryPage = lazy(
  () => import("../SkytrustModules/Administration/Country/CountryPage")
);
const DepartmentIndexPage = lazy(
  () =>
    import("../SkytrustModules/Administration/Department/DepartmentIndexPage")
);
const EmploymentBasisPage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/EmploymentBasis/EmploymentBasisPage"
    )
);
const EnvAspectPage = lazy(
  () => import("../SkytrustModules/Administration/EnvAspect/EnvAspectPage")
);
const EnvImpactTypePage = lazy(
  () =>
    import("../SkytrustModules/Administration/EnvImpactType/EnvImpactTypePage")
);
const GroupDetails = lazy(
  () => import("../SkytrustModules/Administration/Group/GroupDetails")
);
const GroupPage = lazy(
  () => import("../SkytrustModules/Administration/Group/GroupPage")
);
const HazardTypePage = lazy(
  () => import("../SkytrustModules/Administration/HazardType/HazardTypePage")
);
const InventoryPage = lazy(
  () => import("../SkytrustModules/Administration/Inventory/InventoryPage")
);
const LanguagePage = lazy(
  () => import("../SkytrustModules/Administration/Language/LanguagePage")
);
const LocationIndexPage = lazy(
  () => import("../SkytrustModules/Administration/Location/LocationIndexPage")
);
const RAFDocumentIndexPage = lazy(
  () => import("../SkytrustModules/Document/DocumentIndex/RAFDocumentIndexPage")
);
const DocumentContentDetails = lazy(
  () => import("../SkytrustModules/Document/Library/DocumentContentDetails")
);
const AcknowledgementTrackingIndexPage = lazy(
  () => import("../SkytrustModules/Document/AcknowledgementTracking/AcknowledgementTrackingIndexPage")
);
const LocationDetailsPage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/Location/LocationDetails/LocationDetailsPage"
    )
);
const OtherIncidentTypePage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/OtherIncidentType/OtherIncidentTypePage"
    )
);
const PropertyDamageTypePage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/PropertyDamageType/PropertyDamageTypePage"
    )
);
const SubcontractorPage = lazy(
  () =>
    import("../SkytrustModules/Administration/Subcontractor/SubcontractorPage")
);
const TagPage = lazy(
  () => import("../SkytrustModules/Administration/Tag/TagPage")
);
const TerminologyPage = lazy(
  () => import("../SkytrustModules/Administration/Terminology/TerminologyPage")
);
const ThirdPartyTypePage = lazy(
  () =>
    import(
      "../SkytrustModules/Administration/ThirdPartyType/ThirdPartyTypePage"
    )
);
const CreateRiskRegister = lazy(
  () => import("../SkytrustModules/RiskRegister/CreateRiskRegister")
);
const RiskRegisterDetails = lazy(
  () => import("../SkytrustModules/RiskRegister/RiskRegisterDetails")
);
const UpdateRiskRegister = lazy(
  () => import("../SkytrustModules/RiskRegister/UpdateRiskRegister")
);
const RAFViewIndexListPage = lazy(
  () => import("../RAFModules/Common/Views/RAFViewIndexListPage")
);
const ReportAnalysisPage = lazy(
  () => import("../RAFMaster/RMModules/Reports/ReportAnalysisPage")
);
const CreateHazard = lazy(
  () =>
    import("../SkytrustModules/Safety/Incident/AssociatedHazard/CreateHazard")
);
const HazardDetails = lazy(
  () =>
    import("../SkytrustModules/Safety/Incident/AssociatedHazard/HazardDetails")
);
const UpdateHazard = lazy(
  () =>
    import("../SkytrustModules/Safety/Incident/AssociatedHazard/UpdateHazard")
);
const CommunityImpactDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/CommunityImpact/CommunityImpactDetails"
    )
);
const CreateCommunityImpact = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/CommunityImpact/CreateCommunityImpact"
    )
);
const UpdateCommunityImpact = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/CommunityImpact/UpdateCommunityImpact"
    )
);
const CreateEnvironmentalImpact = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/EnvironmentalImpact/CreateEnvironmentalImpact"
    )
);
const EnvironmentalImpactDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/EnvironmentalImpact/EnvironmentalImpactDetails"
    )
);
const UpdateEnvironmentalImpact = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/EnvironmentalImpact/UpdateEnvironmentalImpact"
    )
);
const CreateIncidentSecurity = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/IncidentSecurity/CreateIncidentSecurity"
    )
);
const IncidentSecurityDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/IncidentSecurity/IncidentSecurityDetails"
    )
);
const UpdateIncidentSecurity = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/IncidentSecurity/UpdateIncidentSecurity"
    )
);
const CreateOtherIncident = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/OtherIncident/CreateOtherIncident"
    )
);
const OtherIncidentDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/OtherIncident/OtherIncidentDetails"
    )
);
const UpdateOtherIncident = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/OtherIncident/UpdateOtherIncident"
    )
);
const CreatePersonalInjury = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PersonalInjury/CreatePersonalInjury"
    )
);
const PersonalInjuryDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PersonalInjury/PersonalInjuryDetails"
    )
);
const UpdatePersonalInjury = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PersonalInjury/UpdatePersonalInjury"
    )
);
const CreatePropertyDamage = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PropertyDamage/CreatePropertyDamage"
    )
);
const PropertyDamageDetails = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PropertyDamage/PropertyDamageDetails"
    )
);
const UpdatePropertyDamage = lazy(
  () =>
    import(
      "../SkytrustModules/Safety/Incident/PropertyDamage/UpdatePropertyDamage"
    )
);
const RiskRegisterPage = lazy(
  () => import("./../SkytrustModules/RiskRegister/RiskRegisterPage")
);
const UserDetailsPage = lazy(
  () => import("../SkytrustModules/User/UserDetailsPage")
);
const SwitchTenantPage = lazy(() => import("../helpers/SwitchTenantPage"));

const ActivatePage = lazy(() => import("./../pages/ActivatePage"));
const HomePage = lazy(() => import("./../pages/HomePage"));
const Dashboard = lazy(
  () => import("./../SkytrustModules/Dashboards/Dashboard")
);

const ChangeBusinessUnit = lazy(
  () => import("./../SkytrustModules/BusinessUnit/RAFBusinessUnit")
);
const MenuPage = lazy(() => import("../SkytrustModules/MenuPage/MenuPage"));
const EmployeeCardProfile = lazy(
  () =>
    import(
      "../SkytrustModules/HR/Employee/UnAuthenticatedComponents/EmployeeCardProfile"
    )
);
const EquipmentDetails = lazy(
  () =>
    import(
      "../SkytrustModules/PlantEquipment/UnAuthenticatedComponents/EquipmentDetails"
    )
);
const EmployeeIndexPage = lazy(
  () => import("../SkytrustModules/HR/Employee/EmployeeIndex/EmployeeIndexPage")
);
const CurrentEmployeePage = lazy(
  () => import("../SkytrustModules/HR/Employee/CurrentEmployeePage")
);
const PastEmployeePage = lazy(
  () => import("../SkytrustModules/HR/Employee/PastEmployeePage")
);
const SubcontractorsEmployeePage = lazy(
  () => import("../SkytrustModules/HR/Employee/SubcontractorsEmployeePage")
);
const CreateEmployee = lazy(
  () => import("../SkytrustModules/HR/Employee/ManageEmployee/CreateEmployee")
);
const EmployeeTransactions = lazy(
  () =>
    import(
      "../SkytrustModules/HR/Employee/EmployeeDetailsIndex/EmployeeTransactions"
    )
);
const UpdateEmployee = lazy(
  () => import("../SkytrustModules/HR/Employee/ManageEmployee/UpdateEmployee")
);
const IncidentIndexPage = lazy(
  () =>
    import(
      "./../SkytrustModules/Safety/Incident/IncidentIndex/IncidentIndexPage"
    )
);
const CreateIncident = lazy(
  () => import("./../SkytrustModules/Safety/Incident/CreateIncident")
);
const UpdateIncident = lazy(
  () => import("../SkytrustModules/Safety/Incident/UpdateIncident")
);
const IncidentDetails = lazy(
  () => import("../SkytrustModules/Safety/Incident/IncidentDetails")
);
const CreateChecklist = lazy(
  () => import("../SkytrustModules/Checklist/CreateChecklist")
);
const ChecklistPage = lazy(
  () => import("../SkytrustModules/Checklist/ChecklistPage")
);
const UpdateChecklist = lazy(
  () => import("../SkytrustModules/Checklist/UpdateChecklist")
);
const ChecklistDetails = lazy(
  () => import("../SkytrustModules/Checklist/ChecklistDetails")
);
const ChecklistTemplateList = lazy(
  () => import("../SkytrustModules/ChecklistTemplate/ChecklistTemplatePage")
);
const CreateChecklistTemplate = lazy(
  () => import("../SkytrustModules/ChecklistTemplate/CreateChecklistTemplate")
);
const UpdateChecklistTemplate = lazy(
  () => import("../SkytrustModules/ChecklistTemplate/UpdateChecklistTemplate")
);
const ChecklistTemplateDetails = lazy(
  () => import("../SkytrustModules/ChecklistTemplate/ChecklistTemplateDetails")
);
const RiskMatrixPage = lazy(
  () => import("../SkytrustModules/Administration/RiskMatrix/RiskMatrixPage")
);
const JobTitlePage = lazy(
  () => import("../SkytrustModules/Administration/JobTitle/JobTitlePage")
);
const RAFIndexPage = lazy(
  () => import("../RAFModules/Common/List/RAFIndexPage")
);
const UserIndexpage = lazy(
  () => import("../SkytrustModules/User/UserIndex/UserIndexpage")
);

const MigrateInspectionIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/MigrateInspections/InspectionIndexPage"
    )
);

const MigrateInspectionDetailspage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/MigrateInspections/InspectionDetails"
    )
);

const MigrateInductionIndexPage = lazy(
  () =>
    import("../SkytrustModules/Inductions/MigrateInduction/InductionIndexPage")
);

const MigrateInductionDetailspage = lazy(
  () =>
    import("../SkytrustModules/Inductions/MigrateInduction/InductionDetails")
);

const InspectionLibraryIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionPro/InspectionLibraryIndexPage"
    )
);

const RAFManageInspectionLibrary = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionPro/RAFManageInspectionLibrary"
    )
);

const RAFManageInspectionNewLibrary = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionNew/RAFManageInspectionNewLibrary"
    )
);

const ProcessLibraryIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/ProcessLibrary/ProcessLibraryIndexPage"
    )
);

const RAFManageProcessTemplate = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/ProcessLibrary/RAFManageProcessTemplate"
    )
);

const RAFCreateProcessPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/ProcessLibrary/CreateProcessPage"
    )
);

const RAFUpdateProcessPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/ProcessLibrary/UpdateProcessPage"
    )
);

const RAFDetailsProcessPage = lazy(
  () =>
    import("../SkytrustModules/InspectionsAudits/ProcessLibrary/ProcessDetails")
);

const ProcessLibraryRecordIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/ProcessLibrary/ProcessRecordsIndexPage"
    )
);

const InspectionRecordIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionRecords/InspectionRecordIndexPage"
    )
);

const InspectionRecordCreatePage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionRecords/InspectionRecordCreatePage"
    )
);

const InspectionRecordUpdatePage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionRecords/UpdateInspection"
    )
);

const InspectionRecordDetailspage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionRecords/InspectionDetails"
    )
);

const InspectionScheduleIndexPage = lazy(
  () =>
    import(
      "../SkytrustModules/InspectionsAudits/InspectionSchedule/InspectionScheduleIndexPage"
    )
);

const RAFFormLibraryListPage = lazy(
  () =>
    import(
      "../RAFMaster/RMModules/FormLibrary/FormLibraryIndex/FormLibraryIndexPage"
    )
);

const RAFFormLibraryDetailsPage = lazy(
  () =>
    import(
      "../RAFMaster/RMModules/FormLibrary/FormLibraryIndex/RAFManageFormLibrary"
    )
);

//const CorporateRiskRegisterPage = lazy(() => import("../SkytrustModules/CorporateRisk/CorporateRiskRegisterPage"));
//const CreateCorporateRiskRegister = lazy(() => import("../SkytrustModules/CorporateRisk/CreateCorporateRiskRegister"));

// Instead of BrowserRouter, we use the regular router,
// but we pass in a customer history to it.
//export const history = createHistory.createBrowserHistory();

//const SwitchComponent = this.state.animate ? SwitchWithSlide : Routes;
//const SwitchComponent = SwitchWithSlide ;

export const AppRouterUnAuthenticated = withRouter(({ location }) => {
  return (
    <Suspense fallback={<div></div>}>
      <Routes
        location={location}
      //key={location.pathname}
      >
        <Route
          key={"/activate"}
          path={"/activate"}
          element={<ActivatePage />}
        />
        <Route
          key={"/SecureIDSignUp"}
          path={"/SecureIDSignUp"}
          element={<ActivatePage loginMethod="SecureIDSignUp" />}
        />
        <Route
          key={"/MSSignUp"}
          path={"/MSSignUp"}
          element={<ActivatePage loginMethod="MSSignUp" />}
        />
        <Route
          key={"/logout/:accountType?"}
          path={"/logout/:accountType?"}
          element={<LogoutPage />}
        />
        <Route
          key={"/HR/EmployeeCardProfile/:token"}
          path={"/HR/EmployeeCardProfile/:token"}
          element={<EmployeeCardProfile />}
        />
        <Route
          key={"/Location/view/:id"}
          path={"/Location/view/:id"}
          element={<LocationDetailsPage />}
        />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </Suspense>
  );
});

export const AppRouter = withRouter(({ location }) => {
  //const location = props.router.location;
  return (
    <Suspense fallback={<div></div>}>
      <Routes
        location={location}
      //key={location.pathname}
      >
        <Route
          //exact
          key={"/login"}
          path={"/login"}
          element={<LoginPage />}
        />
        <Route
          //exact
          key={"/logout/:accountType?"}
          path={"/logout/:accountType?"}
          element={<LogoutPage />}
        />

        <Route
          key={"/activate"}
          path={"/activate"}
          element={<ActivatePage />}
        />
        <Route
          key={"/SecureIDSignUp"}
          path={"/SecureIDSignUp"}
          element={<ActivatePage loginMethod="SecureIDSignUp" />}
        />
        <Route
          key={"/MSSignUp"}
          path={"/MSSignUp"}
          element={<ActivatePage loginMethod="MSSignUp" />}
        />
        {/* <Route key={"/"} path={"/"} element={<HomePage />} /> */}
        <Route key={"/"} path={"/"} element={<Dashboard />} />
        <Route
          key={"/authsuccess"}
          path={"/authsuccess"}
          element={<AuthSuccessPage />}
        />
        <Route
          key={"/HR/EmployeeCardProfile/:token"}
          path={"/HR/EmployeeCardProfile/:token"}
          element={<EmployeeCardProfile />}
        />
        <Route
          key={"/PlantEquipment/EquipmentDetails/:token"}
          path={"/PlantEquipment/EquipmentDetails/:token"}
          element={<EquipmentDetails />}
        />
        {/* {pages.map((page) => {
                return (
                    <Route
                        key={page.path}
                        path={page.path}
                        element={
                            !msalInstance.isAuthenticated ? <Navigate to={'/'} /> : <div className="page-wrapper-Container"> <page.Component /></div>

                        }
                    />
                );
            })} */}

        <Route
          key={"/ChangeBusinessUnit"}
          path={"/ChangeBusinessUnit"}
          element={<ChangeBusinessUnit />}
        />
        <Route
          key={"/SwitchTenant"}
          path={"/SwitchTenant"}
          element={<SwitchTenantPage />}
        />
        <Route key={"/HR/Menu"} path={"/HR/Menu"} element={<MenuPage />} />
        <Route
          key={"/Quality/Menu"}
          path={"/Quality/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Safety/Menu"}
          path={"/Safety/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Environment/Menu"}
          path={"/Environment/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/InspectionsAudits/Menu"}
          path={"/InspectionsAudits/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/PlantEquipment/Menu"}
          path={"/PlantEquipment/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Inductions/Menu"}
          path={"/Inductions/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Subcontractors/Menu"}
          path={"/Subcontractors/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Projects/Menu"}
          path={"/Projects/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/InjuryManagement/Menu"}
          path={"/InjuryManagement/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/ClientsSuppliers/Menu"}
          path={"/ClientsSuppliers/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/SignInRegister/Menu"}
          path={"/SignInRegister/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Administration/Menu"}
          path={"/Administration/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Reports/Menu"}
          path={"/Reports/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/taskdashboard"}
          path={"/taskdashboard"}
          element={<TaskDashboard />}
        />
        <Route
          key={"/HR/Employee/List"}
          path={"/HR/Employee/List"}
          element={
            <EmployeeIndexPage
              key={RAFEntityName.Employee}
              moduleName={RAFEntityName.Employee}
            />
          }
        />

        <Route
          key={"/Migrate/Inspection/List"}
          path={"/Migrate/Inspection/List"}
          element={
            <MigrateInspectionIndexPage
            // key={RAFEntityName.Employee}
            // moduleName={RAFEntityName.Employee}
            />
          }
        />
        <Route
          key={"/Migrate/Inspection/Designer/:id"}
          path={"/Migrate/Inspection/Designer/:id"}
          element={<MigrateInspectionDetailspage />}
        />

        <Route
          key={"/Migrate/Induction/List"}
          path={"/Migrate/Induction/List"}
          element={
            <MigrateInductionIndexPage
              // key={RAFEntityName.Employee}
              moduleName={RAFEntityName.Induction}
            />
          }
        />
        <Route
          key={"/Migrate/Induction/Designer/:id"}
          path={"/Migrate/Induction/Designer/:id"}
          element={<MigrateInductionDetailspage />}
        />

        <Route //for same module form library start new migrated inspection
          key={"/InspectionNew/List"}
          path={"/InspectionNew/List"}
          // element={
          //   <InspectionNewLibraryIndexPage
          //   // key={RAFEntityName.Employee}
          //   //moduleName={RAFEntityName.InspectionLibrary}
          //   //indexMode={"Inspection"}
          //   />
          // }
          element={<InspectionScheduleIndexPage />}
        />

        <Route
          key={"/InspectionNew/Designer/:formId"}
          path={"/InspectionNew/Designer/:formId"}
          element={<RAFManageInspectionNewLibrary />}
        />

        <Route
          key={"/ProcessLibrary/List"}
          path={"/ProcessLibrary/List"}
          element={<ProcessLibraryIndexPage />}
        />

        <Route
          key={"/ProcessLibrary/Designer/:formId"}
          path={"/ProcessLibrary/Designer/:formId"}
          element={<RAFManageProcessTemplate />}
        />

        <Route
          key={"/Process/Create/:formId"}
          path={"/Process/Create/:formId"}
          element={<RAFCreateProcessPage />}
        />
        <Route
          key={"/Process/Update/:recordId"}
          path={"/Process/Update/:recordId"}
          element={<RAFUpdateProcessPage />}
        />
        <Route
          key={"/Process/View/:recordId"}
          path={"/Process/View/:recordId"}
          element={<RAFDetailsProcessPage />}
        />

        <Route
          key={"/ProcessLibrary/Records/:formId"}
          path={"/ProcessLibrary/Records/:formId"}
          element={<ProcessLibraryRecordIndexPage />}
        />

        <Route
          key={"/InspectionNew/Records/:formId"}
          path={"/InspectionNew/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />
        <Route
          key={"/InspectionNew/Create/:formId"}
          path={"/InspectionNew/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/InspectionNew/Update/:recordId"}
          path={"/InspectionNew/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/InspectionNew/View/:recordId"}
          path={"/InspectionNew/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />

        <Route //for same module form library start
          key={"/ESIOForms/Inspection/List"}
          path={"/ESIOForms/Inspection/List"}
          // element={
          //   <InspectionLibraryIndexPage
          //     selectedCategoryType={"Inspection"}
          //     selectedCategoryDisplayName={"Inspections Pro"}
          //     // key={RAFEntityName.Employee}
          //     //moduleName={RAFEntityName.InspectionLibrary}
          //     //indexMode={"Inspection"}
          //   />}
          element={
            <InspectionScheduleIndexPage
              selectedCategoryType={"Inspection"}
              selectedCategoryDisplayName={"Inspections Pro"}
            />
          }
        />

        <Route
          key={"/ESIOForms/Inspection/Designer/:formId"}
          path={"/ESIOForms/Inspection/Designer/:formId"}
          element={<RAFManageInspectionLibrary />}
        />
        <Route
          key={"/ESIOForms/Inspection/Records/:formId"}
          path={"/ESIOForms/Inspection/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />
        <Route
          key={"/ESIOForms/Inspection/Create/:formId"}
          path={"/ESIOForms/Inspection/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/ESIOForms/Inspection/Update/:recordId"}
          path={"/ESIOForms/Inspection/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/ESIOForms/Inspection/View/:recordId"}
          path={"/ESIOForms/Inspection/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />

        <Route //for same module form library start
          key={"/ESIOForms/Permit/List"}
          path={"/ESIOForms/Permit/List"}
          element={
            <InspectionLibraryIndexPage
              selectedCategoryType={"Permit"}
              selectedCategoryDisplayName={"Permits"}
            // key={RAFEntityName.Employee}
            //moduleName={RAFEntityName.InspectionLibrary}
            //indexMode={"Inspection"}
            />
          }
        />
        <Route
          key={"/ESIOForms/Permit/Designer/:formId"}
          path={"/ESIOForms/Permit/Designer/:formId"}
          element={<RAFManageInspectionLibrary />}
        />
        <Route
          key={"/ESIOForms/Permit/Records/:formId"}
          path={"/ESIOForms/Permit/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />

        <Route
          key={"/ESIOForms/Permit/Create/:formId"}
          path={"/ESIOForms/Permit/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/ESIOForms/Permit/Update/:recordId"}
          path={"/ESIOForms/Permit/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/ESIOForms/Permit/View/:recordId"}
          path={"/ESIOForms/Permit/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />
        <Route //for same module form library start
          key={"/ESIOForms/Evaluation/List"}
          path={"/ESIOForms/Evaluation/List"}
          element={
            <InspectionLibraryIndexPage
              selectedCategoryType={"Evaluation"}
              selectedCategoryDisplayName={"Evaluations"}
            // key={RAFEntityName.Employee}
            //moduleName={RAFEntityName.InspectionLibrary}
            //indexMode={"Inspection"}
            />
          }
        />

        <Route
          key={"/ESIOForms/Evaluation/Designer/:formId"}
          path={"/ESIOForms/Evaluation/Designer/:formId"}
          element={<RAFManageInspectionLibrary />}
        />
        <Route
          key={"/ESIOForms/Evaluation/Records/:formId"}
          path={"/ESIOForms/Evaluation/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />
        <Route
          key={"/ESIOForms/Evaluation/Create/:formId"}
          path={"/Evaluation/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/ESIOForms/Evaluation/Update/:recordId"}
          path={"/ESIOForms/Evaluation/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/ESIOForms/Evaluation/View/:recordId"}
          path={"/ESIOForms/Evaluation/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />

        <Route
          key={"/FormLibrary/List"}
          path={"/FormLibrary/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFFormLibraryListPage /> */}
              <InspectionLibraryIndexPage
                selectedCategoryType={"FormLibrary"}
                selectedCategoryDisplayName={"Form Library"}
              // key={RAFEntityName.Employee}
              //moduleName={RAFEntityName.InspectionLibrary}
              //indexMode={"Inspection"}
              />
            </div>
          }
        />
        {/* <Route
          key={"/FormLibrary/Designer/:id"}
          path={"/FormLibrary/Designer/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFFormLibraryDetailsPage />
            </div>
          }
        /> */}
        <Route
          key={"/FormLibrary/Designer/:formId"}
          path={"/FormLibrary/Designer/:formId"}
          element={<RAFManageInspectionLibrary />}
        />

        <Route
          key={"/FormLibrary/Records/:formId"}
          path={"/FormLibrary/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />
        <Route
          key={"/FormLibrary/Create/:formId"}
          path={"/FormLibrary/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/FormLibrary/Update/:recordId"}
          path={"/FormLibrary/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/FormLibrary/View/:recordId"}
          path={"/FormLibrary/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />

        <Route
          key={"/Induction/List"}
          path={"/Induction/List"}
          element={
            <div className="page-wrapper-Container">
              <InspectionLibraryIndexPage
                selectedCategoryType={"Induction"}
                selectedCategoryDisplayName={"Inductions"}
              />
            </div>
          }
        />

        <Route
          key={"/Induction/Designer/:formId"}
          path={"/Induction/Designer/:formId"}
          element={<RAFManageInspectionLibrary />}
        />

        <Route
          key={"/Induction/Records/:formId"}
          path={"/Induction/Records/:formId"}
          element={<InspectionRecordIndexPage />}
        />
        <Route
          key={"/Induction/Create/:formId"}
          path={"/Induction/Create/:formId"}
          element={<InspectionRecordCreatePage />}
        />
        <Route
          key={"/Induction/Update/:recordId"}
          path={"/Induction/Update/:recordId"}
          element={<InspectionRecordUpdatePage />}
        />
        <Route
          key={"/Induction/View/:recordId"}
          path={"/Induction/View/:recordId"}
          element={<InspectionRecordDetailspage />}
        />

        <Route
          key={"/InspectionSchedule/List"}
          path={"/InspectionSchedule/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <InspectionScheduleIndexPage />
            </div>
          }
        />
        <Route
          key={"/User/List"}
          path={"/User/List"}
          element={
            <UserIndexpage
              key={RAFEntityName.User}
              moduleName={RAFEntityName.User}
            />
          }
        />
        <Route
          key={"/user/view/:id"}
          path={"/user/view/:id"}
          element={<UserDetailsPage />}
        />
        <Route
          key={"/BusinessUnit/List"}
          path={"/BusinessUnit/List"}
          element={
            <RAFIndexPage
              key={RAFEntityName.Portal}
              moduleName={RAFEntityName.Portal}
            />
          }
        />
        <Route
          key={"/StandardizedLibrary/List"}
          path={"/StandardizedLibrary/List"}
          element={
            <RAFIndexPage
              key={RAFEntityName.StandardizedLibrary}
              moduleName={RAFEntityName.StandardizedLibrary}
            />
          }
        />
        <Route
          key={"/Tenant/List"}
          path={"/Tenant/List"}
          element={
            <RAFIndexPage
              key={RAFEntityName.Tenant}
              moduleName={RAFEntityName.Tenant}
            />
          }
        />
        <Route
          key={"/PermissionGroup/List"}
          path={"/PermissionGroup/List"}
          element={
            <RAFIndexPage
              key={RAFEntityName.Role}
              moduleName={RAFEntityName.Role}
            />
          }
        />
        <Route
          key={"/HR/CurrentEmployee/List"}
          path={"/HR/CurrentEmployee/List"}
          element={<CurrentEmployeePage />}
        />
        <Route
          key={"/HR/PastEmployee/List"}
          path={"/HR/PastEmployee/List"}
          element={<PastEmployeePage />}
        />
        <Route
          key={"/HR/SubcontractorsEmployee/List"}
          path={"/HR/SubcontractorsEmployee/List"}
          element={<SubcontractorsEmployeePage />}
        />
        <Route
          key={"/HR/Employee/Create"}
          path={"/HR/Employee/Create"}
          element={<CreateEmployee />}
        />
        <Route
          key={"/HR/Employee/View/:id"}
          path={"/HR/Employee/View/:id"}
          element={<EmployeeTransactions />}
        />
        <Route
          key={"/MyProfile"}
          path={"/MyProfile"}
          element={<EmployeeTransactions isMyProfile={true} />}
        />
        <Route
          key={"/HR/Employee/Update/:id"}
          path={"/HR/Employee/Update/:id"}
          element={<UpdateEmployee />}
        />
        <Route
          key={"/Safety/Incident/List"}
          path={"/Safety/Incident/List"}
          element={<IncidentIndexPage />}
        />
        <Route
          key={"/Safety/Incident/Create"}
          path={"/Safety/Incident/Create"}
          element={<CreateIncident />}
        />
        <Route
          key={"/Safety/Incident/Update/:id"}
          path={"/Safety/Incident/Update/:id"}
          element={<UpdateIncident />}
        />
        <Route
          key={"/Safety/Incident/View/:id"}
          path={"/Safety/Incident/View/:id"}
          element={<IncidentDetails />}
        />

        <Route
          key={"/Checklist/Create"}
          path={"/Checklist/Create"}
          element={<CreateChecklist />}
        />
        <Route
          key={"/Checklist/List"}
          path={"/Checklist/List"}
          element={<ChecklistPage />}
        />
        <Route
          key={"/Checklist/Update/:id"}
          path={"/Checklist/Update/:id"}
          element={<UpdateChecklist />}
        />
        <Route
          key={"/Checklist/View/:id"}
          path={"/Checklist/View/:id"}
          element={<ChecklistDetails />}
        />
        <Route
          key={"/ChecklistTemplate/List"}
          path={"/ChecklistTemplate/List"}
          element={<ChecklistTemplateList />}
        />
        <Route
          key={"/ChecklistTemplate/Create"}
          path={"/ChecklistTemplate/Create"}
          element={<CreateChecklistTemplate />}
        />
        <Route
          key={"/ChecklistTemplate/Update/:id"}
          path={"/ChecklistTemplate/Update/:id"}
          element={<UpdateChecklistTemplate />}
        />
        <Route
          key={"/ChecklistTemplate/View/:id"}
          path={"/ChecklistTemplate/View/:id"}
          element={<ChecklistTemplateDetails />}
        />
        <Route
          key={"/Apprentice/List"}
          path={"/Apprentice/List"}
          element={<ApprenticePage />}
        />
        <Route
          key={"/Area/List"}
          path={"/Area/List"}
          element={<AreaIndexPage />}
        />
        <Route
          key={"/Company/List"}
          path={"/Company/List"}
          element={<CompanyPage />}
        />
        <Route
          key={"/Country/List"}
          path={"/Country/List"}
          element={<CountryPage />}
        />
        {/* <Route
                    key={"/BusinessUnit/List"}
                    path={"/BusinessUnit/List"}
                    element={<BusinessUnitPage />}
                /> */}
        <Route
          key={"/AgencyOfInjury/List"}
          path={"/AgencyOfInjury/List"}
          element={<CodesAgencyPage />}
        />
        <Route
          key={"/MechanismOfInjury/List"}
          path={"/MechanismOfInjury/List"}
          element={<CodesMechanismPage />}
        />
        <Route
          key={"/NatureOfInjury/List"}
          path={"/NatureOfInjury/List"}
          element={<CodesNaturePage />}
        />
        <Route
          key={"/Department/List"}
          path={"/Department/List"}
          element={<DepartmentIndexPage />}
        />
        <Route
          key={"/EmploymentBasis/List"}
          path={"/EmploymentBasis/List"}
          element={<EmploymentBasisPage />}
        />
        <Route
          key={"/EnvAspect/List"}
          path={"/EnvAspect/List"}
          element={<EnvAspectPage />}
        />
        <Route
          key={"/EnvImpactType/List"}
          path={"/EnvImpactType/List"}
          element={<EnvImpactTypePage />}
        />
        <Route
          key={"/Group/List"}
          path={"/Group/List"}
          element={<GroupPage />}
        />
        <Route
          key={"/Group/View/:id"}
          path={"/Group/View/:id"}
          element={<GroupDetails />}
        />
        <Route
          key={"/HazardType/List"}
          path={"/HazardType/List"}
          element={<HazardTypePage />}
        />
        <Route
          key={"/RiskMatrix/Modify"}
          path={"/RiskMatrix/Modify"}
          element={<RiskMatrixPage />}
        />
        <Route
          key={"/JobTitle/List"}
          path={"/JobTitle/List"}
          element={<JobTitlePage />}
        />
        <Route
          key={"/Language/List"}
          path={"/Language/List"}
          element={<LanguagePage />}
        />
        <Route
          key={"/Location/List"}
          path={"/Location/List"}
          element={<LocationIndexPage />}
        />
        <Route
          key={"/Location/view/:id"}
          path={"/Location/view/:id"}
          element={<LocationDetailsPage />}
        />
        <Route
          key={"/document/List"}
          path={"/document/List"}
          element={<RAFDocumentIndexPage />}
        />
        <Route
          key={"/Document/View/:id"}
          path={"/Document/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFHtmlPageContentDetails /> */}
              <DocumentContentDetails />
            </div>
          }
        />
        <Route
          key={"/acknowledgementtracking/List"}
          path={"/acknowledgementtracking/List"}
          element={<AcknowledgementTrackingIndexPage
            key={RAFEntityName.AcknowledgementTracking}
          />}
        />
        <Route
          key={"/OtherIncidentType/List"}
          path={"/OtherIncidentType/List"}
          element={<OtherIncidentTypePage />}
        />
        <Route
          key={"/PropertyDamageType/List"}
          path={"/PropertyDamageType/List"}
          element={<PropertyDamageTypePage />}
        />
        <Route
          key={"/Subcontractor/List"}
          path={"/Subcontractor/List"}
          element={<SubcontractorPage />}
        />
        <Route key={"/Tag/List"} path={"/Tag/List"} element={<TagPage />} />
        <Route
          key={"/Terminology/List"}
          path={"/Terminology/List"}
          element={<TerminologyPage />}
        />
        <Route
          key={"/ThirdPartyType/List"}
          path={"/ThirdPartyType/List"}
          element={<ThirdPartyTypePage />}
        />
        <Route
          key={"/Inventory/List"}
          path={"/Inventory/List"}
          element={<InventoryPage />}
        />
        <Route
          key={"/Incident/PersonalInjury/Create"}
          path={"/Incident/PersonalInjury/Create"}
          element={<CreatePersonalInjury isActive />}
        />
        <Route
          key={"/Incident/PersonalInjury/Update/:id"}
          path={"/Incident/PersonalInjury/Update/:id"}
          element={<UpdatePersonalInjury />}
        />
        <Route
          key={"/Incident/PersonalInjury/View/:id"}
          path={"/Incident/PersonalInjury/View/:id"}
          element={<PersonalInjuryDetails />}
        />
        <Route
          key={"/Incident/EnvironmentalImpact/Create"}
          path={"/Incident/EnvironmentalImpact/Create"}
          element={<CreateEnvironmentalImpact />}
        />
        <Route
          key={"/Incident/EnvironmentalImpact/Update/:id"}
          path={"/Incident/EnvironmentalImpact/Update/:id"}
          element={<UpdateEnvironmentalImpact />}
        />
        <Route
          key={"/Incident/EnvironmentalImpact/View/:id"}
          path={"/Incident/EnvironmentalImpact/View/:id"}
          element={<EnvironmentalImpactDetails />}
        />
        <Route
          key={"/Incident/PropertyDamage/Create"}
          path={"/Incident/PropertyDamage/Create"}
          element={<CreatePropertyDamage />}
        />
        <Route
          key={"/Incident/PropertyDamage/Update/:id"}
          path={"/Incident/PropertyDamage/Update/:id"}
          element={<UpdatePropertyDamage />}
        />
        <Route
          key={"/Incident/PropertyDamage/View/:id"}
          path={"/Incident/PropertyDamage/View/:id"}
          element={<PropertyDamageDetails />}
        />
        <Route
          key={"/Incident/CommunityImpact/Create"}
          path={"/Incident/CommunityImpact/Create"}
          element={<CreateCommunityImpact />}
        />
        <Route
          key={"/Incident/CommunityImpact/Update/:id"}
          path={"/Incident/CommunityImpact/Update/:id"}
          element={<UpdateCommunityImpact />}
        />
        <Route
          key={"/Incident/CommunityImpact/View/:id"}
          path={"/Incident/CommunityImpact/View/:id"}
          element={<CommunityImpactDetails />}
        />
        <Route
          key={"/Incident/Hazard/Create"}
          path={"/Incident/Hazard/Create"}
          element={<CreateHazard />}
        />
        <Route
          key={"/Incident/Hazard/Update/:id"}
          path={"/Incident/Hazard/Update/:id"}
          element={<UpdateHazard />}
        />
        <Route
          key={"/Incident/Hazard/View/:id"}
          path={"/Incident/Hazard/View/:id"}
          element={<HazardDetails />}
        />
        <Route
          key={"/Incident/IncidentSecurity/Create"}
          path={"/Incident/IncidentSecurity/Create"}
          element={<CreateIncidentSecurity />}
        />
        <Route
          key={"/Incident/IncidentSecurity/Update/:id"}
          path={"/Incident/IncidentSecurity/Update/:id"}
          element={<UpdateIncidentSecurity />}
        />
        <Route
          key={"/Incident/IncidentSecurity/View/:id"}
          path={"/Incident/IncidentSecurity/View/:id"}
          element={<IncidentSecurityDetails />}
        />
        <Route
          key={"/Incident/OtherIncident/Create"}
          path={"/Incident/OtherIncident/Create"}
          element={<CreateOtherIncident />}
        />
        <Route
          key={"/Incident/OtherIncident/Update/:id"}
          path={"/Incident/OtherIncident/Update/:id"}
          element={<UpdateOtherIncident />}
        />
        <Route
          key={"/Incident/OtherIncident/View/:id"}
          path={"/Incident/OtherIncident/View/:id"}
          element={<OtherIncidentDetails />}
        />
        <Route
          key={"/Administration/Menu"}
          path={"/Administration/Menu"}
          element={<MenuPage />}
        />
        <Route
          key={"/Safety/RiskRegister/List"}
          path={"/Safety/RiskRegister/List"}
          element={<RiskRegisterPage />}
        />
        <Route
          key={"/Safety/RiskRegister/Create"}
          path={"/Safety/RiskRegister/Create"}
          element={<CreateRiskRegister />}
        />
        <Route
          key={"/Safety/RiskRegister/View/:id"}
          path={"/Safety/RiskRegister/View/:id"}
          element={<RiskRegisterDetails />}
        />
        <Route
          key={"/Safety/RiskRegister/Update/:id"}
          path={"/Safety/RiskRegister/Update/:id"}
          element={<UpdateRiskRegister />}
        />
        <Route
          key={"/view/list"}
          path={"/view/list"}
          element={<RAFViewIndexListPage />}
        />
        <Route
          key={"/Report/List"}
          path={"/Report/List"}
          element={
            <div className="page-wrapper-Container">
              <ReportAnalysisPage />
            </div>
          }
        />
      </Routes>
    </Suspense>
  );
  //}
});

export interface NavigateParamsProps {
  params?: any;
  navigate?: NavigateFunction;
}
export function withParamsAndNavigate(Component) {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useNavigate()} />
  );
}
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        params={params}
        navigate={navigate}
      />
    );
  }

  return ComponentWithRouterProp;
}
/*
const pageVariantsSlide = {
    initial: {
        opacity: 0,
        /*x: "-100vw",*/
/*scale: 0.8*/
/* },
 in: {
     opacity: 1,
     /*x: 0,*/
/*scale: 1,*/
/*  },
  out: {
      opacity: 0,
      /*x: "100vw",*/
/*scale: 1.2,*/
/*  },
};*/
